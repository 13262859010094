import { axios } from "./../plugins/axios";
/**
 * @method 获取商品列表
 * @param {*} cid 分类id
 */
export function getProductList(cid) {
  return axios.request({
    params: {
      cid,
      module: "app",
      action: "index",
      app: "product_list_new",
      pageSize: 8
    }
  });
}
