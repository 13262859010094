<template>
  <div>
    <download></download>
    <div class="large-carousel">
      <div>
        <van-notice-bar left-icon="volume-o" background="#c09666" color="#fff">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item v-for="(item, index) in list" :key="index + 's'">{{
              item.text
            }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
      <div class="box-zhuanpan">
        <LuckyWheel
          ref="LuckyWheel"
          class="luck-draw"
          :blocks="blocks"
          :prizes="prizes"
          :buttons="buttons"
          @start="startCallBack"
          @end="endCallBack"
        />
      </div>
      <p class="text">
        你拥有<span style="font-size:.38rem;color:red">{{ num }}次</span
        >抽奖机会
      </p>
      <p class="text">奖品请在APP内查看且使用</p>
      <div class="tab-content">
        <van-tabs
          v-model="active"
          color="#d8a367"
          title-active-color="#d8a367"
          title-inactive-color="#333333"
          line-width="0"
          @click="click"
        >
          <van-tab title="高奢包袋" name="309"></van-tab>
          <van-tab title="珠宝首饰" name="495"></van-tab>
          <van-tab title="大牌腕表" name="496"></van-tab>
          <van-tab title="顶级豪车" name="491"></van-tab>
        </van-tabs>
      </div>
      <div style="padding: 0 .10rem">
        <waterfall :col="2" :data="goodsList">
          <template>
            <!-- <img v-if="item.img" :lazy-src="item.img" alt="load error" /> -->
            <div v-for="(item, index) in goodsList" :key="index">
              <img
                @click="openbox"
                class="img-icon"
                id="img1"
                v-if="item.type == 'img1'"
                src="./../../assets/img/largeCarousel/img1.png"
                alt=""
              />
              <img
                @click="openbox"
                id="img2"
                class="img-icon"
                v-else-if="item.type == 'img2'"
                src="./../../assets/img/largeCarousel/img3.png"
                alt=""
              />
              <img
                @click="openbox"
                id="img3"
                class="img-icon"
                v-else-if="item.type == 'img3'"
                src="./../../assets/img/largeCarousel/img2.png"
                alt=""
              />
              <img
                @click="openbox"
                id="img4"
                class="img-icon"
                v-else-if="item.type == 'img4'"
                src="./../../assets/img/largeCarousel/img4.png"
                alt=""
              />
              <div class="item" v-else @click="openbox">
                <img :src="item.imgurl" alt="" />
                <div class="title u-line-1">{{ item.product_title }}</div>
                <div class="price">
                  <div class="z_price">
                    ￥<span style="font-size:.36rem">{{ item.vip_price }}</span
                    >/天
                  </div>
                  <div class="y_price">￥{{ item.price }}/天</div>
                </div>
                <div class="tip">
                  <i class="icon-zhekou"></i
                  ><span>
                    便宜了{{
                      (item.price * 1 - item.vip_price * 1).toFixed(2)
                    }}元</span
                  >
                </div>
              </div>
            </div>
          </template>
        </waterfall>
      </div>
      <van-popup v-model="show" round closeable :close-on-click-overlay="false">
        <div class="pop-content">
          <div class="title">恭喜中奖</div>
          <img
            class="hongbao"
            src="./../../assets/img/largeCarousel/668hongbao.png"
            alt=""
          />
          <p>恭喜抽中<span style="color:#FE0C49">668元现金红包</span></p>
          <p>请在1号奢仓APP内消费使用。</p>
          <div class="receive-btn" @click="openbox">领取</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { getProductList } from "@/api/largeCarousel";
import { getIp, postShare } from "@/api/report";
import { getDeviceInformation } from "@/uitls/methods";
import download from "@/components/download.vue";
export default {
  components: { download },
  data() {
    return {
      list: [
        { text: "恭喜可爱的小毛驴!抽中了7天免费会员体验券" },
        { text: "恭喜糖果水晶!抽中了Gucci体验3天" },
        { text: "恭喜Anan酱!抽中了Gucci体验3天" },
        { text: "恭喜不会游泳的鱼! 抽中了迪奥花漾甜心" },
        { text: "恭喜咯咯哒哒!抽中了7天免费会员体验券" },
        { text: "恭喜Eay!抽中了7天免费会员体验券" },
        { text: "恭喜波波奶茶!抽中了Gucci体验3天" },
        { text: "恭喜江小白!抽中了7天免费会员体验券" },
        { text: "恭喜橙子!抽中了Gucci体验3天" },
        { text: "恭喜北方没有你啊! 抽中了迪奥花漾甜心" },
        { text: "恭喜虽然!抽中了Gucci体验3天" },
        { text: "恭喜且行珍惜!抽中了7天免费会员体验券" },
        { text: "恭喜妙妙啊!抽中了7天免费会员体验券" },
        { text: "恭喜摆摊卖回忆! 抽中了迪奥花漾甜心" }
      ],
      show: false,
      blocks: [
        {
          imgs: [
            {
              src: require("@/assets/img/largeCarousel/dazhuanpan.png"),
              width: "100%",
              rotate: true
            }
          ]
        }
      ],
      buttons: [
        // { radius: "50px", background: "#d64737" },
        { radius: "40px", background: "#fff" },
        { radius: "32px", background: "#f6c66f", pointer: true },
        {
          radius: "35px",
          background: "#ffdea0",
          fonts: [
            {
              text: "点击",
              top: "-20px",
              fontColor: "#fff",
              fontSize: ".35rem"
            },
            { text: "抽奖", top: "0px", fontColor: "#fff", fontSize: ".35rem" }
          ]
        }
      ],
      prizes: [
        {
          fonts: [
            {
              text: "iPhone 12",
              top: "40px",
              fontColor: "#fff",
              fontSize: "14px"
            }
          ],
          imgs: [
            {
              src: require("./../../assets/img/largeCarousel/j2.png"),
              width: "50px",
              height: "50px",
              top: "70px"
            }
          ]
        },
        {
          fonts: [
            {
              text: "668元现金红包",
              top: "40px",
              fontColor: "#CC9F51",
              fontSize: "14px"
            }
          ],
          imgs: [
            {
              src: require("./../../assets/img/largeCarousel/j1.png"),
              width: "50px",
              height: "50px",
              top: "70px"
            }
          ]
        },
        {
          fonts: [
            {
              text: "Gucci体验3天",
              top: "40px",
              fontColor: "#fff",
              fontSize: "14px"
            }
          ],
          imgs: [
            {
              src: require("./../../assets/img/largeCarousel/j3.png"),
              width: "50px",
              height: "50px",
              top: "70px"
            }
          ]
        },
        {
          fonts: [
            {
              text: "50元满减券",
              top: "40px",
              fontColor: "#CC9F51",
              fontSize: "14px"
            }
          ],
          imgs: [
            {
              src: require("./../../assets/img/largeCarousel/j4.png"),
              width: "50px",
              height: "50px",
              top: "70px"
            }
          ]
        },
        {
          fonts: [
            {
              text: "迪奥花漾甜心",
              top: "40px",
              fontColor: "#fff",
              fontSize: "14px"
            }
          ],
          imgs: [
            {
              src: require("./../../assets/img/largeCarousel/j5.png"),
              width: "50px",
              height: "50px",
              top: "70px"
            }
          ]
        },
        {
          fonts: [
            {
              text: "7天会员体验",
              top: "40px",
              fontColor: "#CC9F51",
              fontSize: "14px"
            }
          ],
          imgs: [
            {
              src: require("./../../assets/img/largeCarousel/j6.png"),
              width: "50px",
              height: "50px",
              top: "70px"
            }
          ]
        }
      ],
      num: 0,
      active: "309",
      goodsList: []
    };
  },
  async created() {
    console.log(window.localStorage.num);
    if (window.localStorage.num == undefined) {
      window.localStorage.num = 1;
    }
    this.num = window.localStorage.num;
    let res = await getProductList(309);
    this.goodsList.push(
      {
        type: "img1"
      },
      ...res.data.data
    );
    let res1 = await getProductList(495);
    this.goodsList.push(
      {
        type: "img2"
      },
      ...res1.data.data
    );
    let res2 = await getProductList(496);
    this.goodsList.push(
      {
        type: "img3"
      },
      ...res2.data.data
    );
    let res3 = await getProductList(491);
    this.goodsList.push(
      {
        type: "img4"
      },
      ...res3.data.data
    );
    console.log(getDeviceInformation(), "获取的设备信息");
    getIp().then(res => {
      if (res.data.code == 200) {
        postShare({
          ip: res.data.data,
          uid: this.$route.query.uid || "",
          rid: this.$route.query.rid || "",
          dev: getDeviceInformation().dev,
          systemversion: getDeviceInformation().systemversion // 版本号
        });
      }
    });
  },
  methods: {
    openbox() {
      // window.location.href =
      //   "https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc";
      location.href = `https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc&android_schema=yhsc://${this
        .$route.query.pageUrl || ""}&ios_schema=${this.$route.query.pageUrl ||
        ""}`;
    },
    click(name) {
      console.log(name);
      switch (name) {
        case "309":
          document.getElementById("img1").scrollIntoView();
          break;
        case "496":
          document.getElementById("img3").scrollIntoView();
          break;
        case "495":
          document.getElementById("img2").scrollIntoView();
          break;
        case "491":
          document.getElementById("img4").scrollIntoView();
          break;
        default:
          break;
      }
    },
    startCallBack() {
      if (this.num == 0) {
        this.$toast("暂无抽奖次数");
        return;
      }
      this.num = 0;
      localStorage.num = 0;
      this.$refs.LuckyWheel.play();
      setTimeout(() => {
        this.$refs.LuckyWheel.stop(1);
      }, 3000);
    },
    endCallBack(prize) {
      console.warn(prize);
      this.show = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.large-carousel {
  background: url("./../../assets/img/largeCarousel/bgc.png") 0 0 no-repeat,
    #f6f0f0;
  background-size: 100%;
  min-height: 1264px;
  // padding-top: 358px;
  // padding-top: 100px;
  .box-zhuanpan {
    position: relative;
    width: 649px;
    height: 649px;
    margin: auto;
    margin-top: 250px;
    margin-bottom: 20px;
    .luck-draw {
      width: 649px;
      height: 649px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .text {
    // height: 26px;
    font-size: 26px;
    font-weight: 600;
    color: #999999;
    line-height: 50px;
    text-align: center;
  }
  .tab-content {
    margin-top: 60px;
    padding: 0 18px;
    z-index: 100;
    position: sticky;
    top: 100px;
    /deep/.van-tabs__wrap {
      border-radius: 14px;
    }
    margin-bottom: 20px;
  }
  .img-icon {
    width: 347px;
    background: #ffffff;
    border-radius: 14px;
    margin: 20px auto;
    display: block;
  }
  .item {
    width: 347px;
    background: #ffffff;
    border-radius: 14px;
    margin: 20px auto;
    img {
      width: 100%;
      border-radius: 14px 14px 0 0;
    }
    .title {
      font-size: 28px;
      font-weight: 600;
      color: #333333;
      line-height: 38px;
      padding: 10px 20px;
    }
    .price {
      display: flex;
      padding: 0 20px;
      .z_price {
        font-size: 21px;
        font-weight: 600;
        color: #e9303c;
        line-height: 38px;
      }
      .y_price {
        font-size: 21px;
        font-weight: 600;
        color: #999999;
        line-height: 38px;
        margin-left: 20px;
        text-decoration: line-through;
      }
    }
    .tip {
      display: inline-block;
      // height: 38px;
      background: #ea333f;
      border-radius: 3px;
      font-size: 19px;
      font-weight: 600;
      color: #ffffff;
      padding: 10px 20px;
      margin: 25px;
      // display: flex;
      line-height: 25px;
      vertical-align: bottom;
      .icon-zhekou {
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url("./../../assets/img/largeCarousel/zhekou.png") 0 0
          no-repeat;
        background-size: 100%;
        // margin-top: 10px;
        vertical-align: bottom;
      }
    }
  }
}
.u-line-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
}
.pop-content {
  width: 625px;
  height: 715px;
  background: #ffffff;
  // border-radius: 50px;
  padding: 20px 0;
  .title {
    font-size: 42px;
    line-height: 82px;
    font-weight: bold;
    color: #ff0544;
    text-align: center;
  }
  .hongbao {
    width: 544px;
    height: 235px;
    display: block;
    margin: auto;
    margin-bottom: 30px;
  }
  p {
    font-size: 28px;
    font-weight: 600;
    color: #999999;
    line-height: 51px;
    text-align: center;
  }
  .receive-btn {
    width: 325px;
    height: 83px;
    background: #333333;
    border-radius: 42px;
    font-size: 38px;
    font-weight: 600;
    color: #ffffff;
    line-height: 83px;
    text-align: center;
    margin: auto;
    margin-top: 100px;
  }
}
</style>
