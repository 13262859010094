import MobileDetect from "mobile-detect";
export function contains(arr, str) {
  for (let i in arr) {
    if (arr[i].indexOf(str) > 0) return i;
  }
  return -1;
}
/**
 * @method 获取设备信息
 * @returns {Object} dev 设备信息 systemversion系统版本
 */
export function getDeviceInformation() {
  //判断数组中是否包含某字符串
  let device_type = navigator.userAgent; //获取userAgent信息
  let md = new MobileDetect(device_type); //初始化mobile-detect
  console.log(md, "md");
  let os = md.os(); //获取系统
  console.log(os, "os");
  let model = "";
  if (os == "iOS") {
    //ios系统的处理
    os = md.os() + md.version("iPhone");
    model = md.mobile();
  } else if (os == "AndroidOS") {
    //Android系统的处理
    os = md.os() + md.version("Android");
    let sss = device_type.split(";");
    let i = contains(sss, "Build/");
    if (i > -1) {
      model = sss[i].substring(0, sss[i].indexOf("Build/"));
    }
  }
  //   this.dev = model; // 手机型号
  //   this.systemversion = os; // 系统版本
  return {
    dev: model,
    systemversion: os
  };

  // console.log('手机型号', model);
  // console.log('系统版本', os);
}
